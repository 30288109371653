<template>
  <div>
    <operations-detail-component-global 
      :sApiGetOC="sApiGetOC" 
      :aHeadersRawMaterials="aHeadersRawMaterials"
      :sTypeItem="2" 
      sBackHref="OPPending" 
      sTextDetail="" 
      :toProvider="toProvider" 
      :bAdminPermissions="bAdminOPPending"
      :bToProvider="true" 
      :itemsBreadcrumbs="itemsBreadcrumbs" 
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      itemsBreadcrumbs: [],
      toProvider: "",
      sApiGetOC: "",
      aHeadersRawMaterials: [
        {
          text: "#",
          sortable: false,
          value: "dNumTransation",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Código",
          sortable: false,
          value: "sCodeRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre de la materia",
          sortable: false,
          value: "sNameRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Peso",
          sortable: false,
          value: "dWeightRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        // {
        //   text: "Precio unitario",
        //   sortable: false,
        //   value: "dUnitPriceRawMaterial",
        //   class: "global-header-table",
        //   cellClass: "global-body-table ",
        // },
        // {
        //   text: "Subtotal",
        //   sortable: false,
        //   value: "dSubtotalRawMaterial",
        //   class: "global-header-table",
        //   cellClass: "global-body-table ",
        // },
        {
          text: "Merma",
          sortable: false,
          value: "dUnprocessedWeight",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Nombre del responsable",
          sortable: false,
          value: "sManagerRawMaterial",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acciones",
          value: "accions",
          align: "center",
          class:
            "global-header-table global-header-accions global-col-header-accion",
          cellClass:
            "global-body-table global-body-accions global-col-body-accion",
        },
      ],
    };
  },
  beforeMount() {
    this.setNameBreadcrumbs();
    this.setApiGetOC();
  },
  methods: {
    setNameBreadcrumbs() {
      let aBreadcrumbs = [
        {
          text: "Operaciones pendientes",
          disabled: false,
          to: { name: "OPPending" },
        },
        {
          text: "Detalle de una operación pendiente",
          link: true,
          exact: true,
          disabled: true,
          to: { name: "OPPendingDetail" },
          params: { id: this.$route.params.idOP },
        },
      ];

      this.itemsBreadcrumbs = aBreadcrumbs;
      this.toProvider = `/home/op-pending-detail/${this.$route.params.idOP}/provider-detail-op-pending`;
    },
    setApiGetOC() {
      this.sApiGetOC = `${URI}/api/sp/v1/orders/specialist/`;
    },
  },
  computed: {
    bShowOPPending() {
      return this.$store.state.bShowOPPending;
    },
    bAdminOPPending() {
      return this.$store.state.bAdminOPPending;
    },
  },
};
</script>

<style>
</style>